@import url(https://fonts.googleapis.com/css?family=Roboto+Mono);

html, body {
  height: 100%; }

body {
  margin: 0;
  padding: 0; }
  body .default-button {
    border: 1px solid #434343;
    background-color: white;
    color: #434343;
    height: 50px;
    line-height: 50px;
    cursor: pointer; }
    body .default-button.disabled {
      border-color: #909090;
      color: #909090;
      cursor: default; }
  body #main-container {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
    display: table;
    font-family: 'Roboto Mono', monospace; }
    body #main-container .row {
      display: table-row;
      height: 100%;
      min-height: 100%; }
      body #main-container .row .no-float {
        display: table-cell;
        float: none; }
    body #main-container .unsupported-text {
      margin-top: 60px;
      line-height: 3; }
  body #sidebar {
    background-color: #434343;
    color: white;
    padding: 40px;
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 400px; }
    body #sidebar #title {
      text-align: center;
      margin-bottom: 40px;
      text-align: center; }
    body #sidebar .explanation {
      text-align: justify;
      line-height: 2; }
  body .main-contents {
    text-align: center;
    padding-top: 60px;
    position: fixed;
    left: 400px;
    top: 0px;
    right: 0px; }
    body .main-contents .container-fluid {
      margin: 0px;
      padding: 0px; }
    body .main-contents .file-section {
      height: 250px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center; }
      body .main-contents .file-section .file-drop {
        border: 2px dashed #909090;
        color: #909090;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%; }
        body .main-contents .file-section .file-drop:hover, body .main-contents .file-section .file-drop.file-drop-active {
          cursor: pointer;
          border-color: #434343;
          color: #434343; }
      body .main-contents .file-section .selected-file .column-select {
        margin-left: 10px; }
        body .main-contents .file-section .selected-file .column-select label {
          display: block; }
      body .main-contents .file-section .selected-file .file-preview .file-icon {
        height: 80px;
        margin-bottom: 15px; }
      body .main-contents .file-section .selected-file .file-preview .remove-file {
        color: #434343;
        font-size: 10px;
        margin-top: 5px; }
        body .main-contents .file-section .selected-file .file-preview .remove-file a {
          cursor: pointer;
          color: #434343; }
    body .main-contents .algorithm-selection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 150px; }
      body .main-contents .algorithm-selection .algorithm-button {
        width: 150px; }
        body .main-contents .algorithm-selection .algorithm-button:hover, body .main-contents .algorithm-selection .algorithm-button.active {
          color: white;
          background-color: #434343; }
        body .main-contents .algorithm-selection .algorithm-button.active:hover {
          cursor: default; }
  body #root .file-input-button {
    margin-top: 50px; }
  body #root .algorithm-select {
    margin-top: 50px; }
  body #root .column-select {
    margin-top: 30px; }
  body #root .download-button {
    width: 100%; }
  body #root input.file-input {
    display: none; }

.selectric-wrapper .selectric {
  background: white;
  border-color: #434343; }
  .selectric-wrapper .selectric .label {
    text-align: left;
    padding: 0px; }
  .selectric-wrapper .selectric .button {
    background: white; }

.selectric-wrapper .selectric-items {
  background-color: white;
  border-color: #434343;
  border-top: none; }
  .selectric-wrapper .selectric-items li {
    text-align: left; }
  .selectric-wrapper .selectric-items li:hover {
    background-color: #434343;
    color: white; }
  .selectric-wrapper .selectric-items .selected, .selectric-wrapper .selectric-items .highlighted {
    background-color: #767676;
    color: white; }

